<template>
	<v-footer padless absolute v-if="$vuetify.breakpoint.lgAndUp || !authenticated" color="background darken-1">
		<v-row justify="center" no-gutters>
			<v-btn text x-small rounded class="my-3" :to="{ name: 'Landing' }" exact v-if="!authenticated">
				{{ $t('nav.home') }}
			</v-btn>
			<v-btn text x-small rounded class="my-3" href="https://blog.oversos.com" target="_blank"> Blog </v-btn>
			<v-btn text x-small rounded class="my-3" :to="{ name: 'Contact' }">
				{{ $t('nav.contactUs') }}
			</v-btn>
			<v-btn text x-small rounded class="my-3" :to="{ name: 'FAQ' }">
				{{ $t('nav.faq') }}
			</v-btn>
			<v-btn text x-small rounded class="my-3" :to="{ name: 'Team' }">
				{{ $t('nav.team') }}
			</v-btn>
			<v-btn text x-small rounded class="my-3" :to="{ name: 'Policies' }">
				{{ $t('policies.title') }}
			</v-btn>
		</v-row>
		<v-row justify="center" no-gutters>
			<v-col class="py-1 text-center" cols="3">
				<v-btn icon href="https://instagram.com/oversos.official" target="_blank">
					<v-icon>mdi-instagram</v-icon>
				</v-btn>
			</v-col>
			<v-col class="py-1 text-center" cols="3">
				<v-btn icon href="https://facebook.com/oversos" target="_blank">
					<v-icon>mdi-facebook</v-icon>
				</v-btn>
			</v-col>
			<v-col class="py-1 text-center" cols="3">
				<v-btn icon href="https://twitter.com/oversosofficial" target="_blank">
					<v-icon>mdi-twitter</v-icon>
				</v-btn>
			</v-col>
			<v-col class="py-1 text-center" cols="3">
				<v-btn icon href="https://linkedin.com/company/oversos" target="_blank">
					<v-icon>mdi-linkedin</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row justify="center" no-gutters>
			<v-col class="py-1 text-center" cols="12"> {{ new Date().getFullYear() }} — &copy; <strong>OverSOS</strong> </v-col>
		</v-row>
	</v-footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'Footer',
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			setupInfo: 'user/setupInfo',
		}),
	},
}
</script>
